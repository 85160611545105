import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Icon,
  HStack,
  Image,
  Stack,
  Container,
  Button,
  Spacer,
  IconButton,
  Divider,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import theme from './theme/theme';
import Background from './assets/images/bg.jpg';
import Dashboard from './assets/images/Dashboard.jpg';
import Report from './assets/images/Report.jpg';
import Vehicle from './assets/images/Vehicle.jpg';
import { SiBmw, SiIveco, SiJeep, SiMan, SiMercedes, SiVolkswagen, SiVolvo } from 'react-icons/si';
import { FiCheckCircle, FiFileText, FiMail, FiShield, FiTruck, FiUsers } from 'react-icons/fi';
import {
  List,
  ListItem,
  useDisclosure,
  ListIcon,
  Center
} from '@chakra-ui/react'

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <ChakraProvider theme={theme}>
      <Modal size={'full'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sumarni izvještaj o vozilima</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Image h={'80vh'} border={'solid 1px'} borderColor={'gray.300'} src={Report} boxShadow={'lg'} rounded={'lg'} />

            </Center>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Zatvori
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box w={'full'} h={'100vh'} backgroundImage={Background} bgSize={'cover'}>
        <Container maxW={'6xl'}>
          <Stack alignItems={['center', '']} direction={['column', 'row']} pt={10} justifyContent={'space-between'} >
            <HStack justifyContent={['center', 'flex-start']} >
              <Text fontSize={'xl'} fontWeight={'bold'}>Upravljanje</Text>
              <Text fontSize={['md', 'xl']} fontWeight={'light'}>Voznim Parkom (UVP.ba)</Text>
            </HStack>

            <Link href='mailto:info@uvp.ba'>info@uvp.ba</Link>

            <Button colorScheme={'purple'} fontSize={'sm'}>Kontaktirajte nas</Button>
          </Stack>

          <Spacer h={['25px', '100px']} />

          <VStack spacing={0}>
            <Text textAlign={'center'} letterSpacing={'-1px'} fontWeight={600} fontSize={['xl', 'xxx-large']} color={'#0E0B3D'}>Ne propustite servis ili registraciju vozila.</Text>
            <Text textAlign={'center'} letterSpacing={'-1px'} fontWeight={600} fontSize={['xl', 'xxx-large']} color={'#0E0B3D'}>Upravljanje vozilima na jednom mjestu.</Text>

            <Text mt={7} fontWeight={400} fontSize={'sm'} textAlign={'center'}>
              Pomažemo Vam da jednostavno i brzo upravljate svojim vozilima. <br />Uz našu aplikaciju možete pratiti servise, registracije, tehničke preglede i još mnogo toga.
            </Text>

            <Image mt={20} w={'7xl'} rounded={'lg'} boxShadow={'2xl'} src={Dashboard} />
          </VStack>

          <Spacer h={'150px'} />

          <Stack direction={['column', 'row']} spacing={20}>
            <Box>
              <Text lineHeight={'60px'} fontSize={'5xl'} fontWeight={600} bgClip={'text'} bgGradient={'linear-gradient(#7845DB, #38245F)'}>Smart <br />obavještenja<br /> za servise i preglede</Text>
              <Text color={'gray.500'} mt={10}>
                Uz naš sistem obavještenja nećete propustiti ni jedan servis ili tehnički pregled. <br />Na vrijeme ćete biti obavješteni o svim potrebnim radnjama putem e-maila i platforme.
              </Text>
            </Box>
            <Box w={'full'}>
              <Tooltip hasArrow placement='top' label="Kliknite na sliku da otvorite u punoj veličini.">
                <Image onClick={onOpen} cursor={'pointer'} src={Report} boxShadow={'2xl'} rounded={'lg'} />
              </Tooltip>
            </Box>
          </Stack>

          <Spacer h={'150px'} />

          <Box position={'relative'} bg={'purple.50'} rounded={'lg'} p={10}>
            <Box display={['none', 'flex']} backdropFilter='auto' backdropBlur='8px' position={'absolute'} top={'-5rem'} w={'8rem'} p={5} boxShadow={'2xl'} rounded={'lg'} right={'40px'}>
              <VStack spacing={8}>
                <IconButton py={7} px={5} fontSize={'3xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiMan} />} />
                <IconButton py={7} px={5} fontSize={'3xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiVolvo} />} />
                <IconButton py={10} px={7} fontSize={'5xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiVolkswagen} />} />
                <IconButton py={7} px={5} fontSize={'3xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiBmw} />} />
                <IconButton py={7} px={5} fontSize={'3xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiJeep} />} />
                <IconButton py={7} px={5} fontSize={'3xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiIveco} />} />
                <IconButton py={7} px={5} fontSize={'3xl'} colorScheme={'purple'} boxShadow={'lg'} icon={<Icon as={SiMercedes} />} />
              </VStack>
            </Box>

            <VStack alignItems={'flex-start'} spacing={5} maxW={'3xl'}>
              <Text fontSize={'3xl'} fontWeight={600}>Kako Vam pomažemo u upravljanju Vašim voznim parkom?</Text>

              <List spacing={3}>
                <ListItem>
                  <HStack>
                    <ListIcon as={FiCheckCircle} color='purple.500' />
                    <Text>Kontrolna ploča sa važnim informacijama i statistikama</Text>
                  </HStack>
                </ListItem>
                <ListItem>
                  <HStack>
                    <ListIcon as={FiCheckCircle} color='purple.500' />
                    <Text>Jedinstveni registar Vaših vozila u voznom parku</Text>
                  </HStack>
                </ListItem>
                <ListItem>
                  <HStack>
                    <ListIcon as={FiCheckCircle} color='purple.500' />
                    <Text>Sumarni izvještaj o vozilima sa indikatorima isteka pregleda i osiguranja</Text>
                  </HStack>
                </ListItem>
                <ListItem>
                  <HStack>
                    <ListIcon as={FiCheckCircle} color='purple.500' />
                    <Text>"Lične karte" vozila sa pratećim informacijama:</Text>
                  </HStack>

                  <List mt={5} ml={10} spacing={3}>
                    <ListItem>
                      <HStack>
                        <ListIcon as={FiCheckCircle} color='purple.500' />
                        <Text>Osnovne informacije poput garaže, broj motora, snaga, euro norma...</Text>
                      </HStack>
                    </ListItem>
                    <ListItem>
                      <HStack>
                        <ListIcon as={FiCheckCircle} color='purple.500' />
                        <Text>Osiguranja od automobilske odgovornosti i registracije vozila</Text>
                      </HStack>
                    </ListItem>
                    <ListItem>
                      <HStack>
                        <ListIcon as={FiCheckCircle} color='purple.500' />
                        <Text>KASKO</Text>
                      </HStack>
                    </ListItem>
                    <ListItem>
                      <HStack>
                        <ListIcon as={FiCheckCircle} color='purple.500' />
                        <Text>Godišnji, šestomjesečni, TEU, Tahograf pregledi</Text>
                      </HStack>
                    </ListItem>
                    <ListItem>
                      <HStack>
                        <ListIcon as={FiCheckCircle} color='purple.500' />
                        <Text>Računi vezani za vozilo</Text>
                      </HStack>
                    </ListItem>
                    <ListItem>
                      <HStack>
                        <ListIcon as={FiCheckCircle} color='purple.500' />
                        <Text>Evidencija guma, kilometraža, servisa i PP Aparata</Text>
                      </HStack>
                    </ListItem>
                  </List>

                </ListItem>
              </List>
            </VStack>
          </Box>

          <Spacer h={'150px'} />

          <Box border={'solid 1px'} borderColor={'gray.200'} backdropFilter='auto' backdropBlur='8px' p={10} boxShadow={'2xl'} rounded={'lg'} right={'40px'}>
            <Text fontSize={'3xl'} fontWeight={600}>Registar vozila i popratnih informacija.</Text>
            <Text fontSize={'sm'} mt={5}>
              Zaboravite na ručno praćenje servisa i popravaka vozila. Naša platforma omogućava automatizirano upravljanje održavanjem, pomažući Vam u sprječavanju kvarova i smanjenju vremena neaktivnosti vozila.
            </Text>
            <Image mt={10} src={Vehicle} border={'solid 1px'} borderColor={'gray.200'} rounded={'lg'} />
          </Box>

          <Spacer h={'150px'} />

          <Stack direction={['column', 'row']} spacing={10}>
            <PricingBox title={'Standard'} price={'99 KM / mj.'}>
              <PricingLine icon={FiTruck} text={'10 vozila u registru'} />
              <PricingLine icon={FiUsers} text={'2 korisnika'} />
              <PricingLine icon={FiFileText} text={'Izvještaj o isteku termina'} />
              <PricingLine icon={FiCheckCircle} text={'Osiguranja, KASKO, Pregledi'} />
              <PricingLine icon={FiCheckCircle} text={'Računi, Gume, Kilometraže, Servisi'} />
            </PricingBox>
            <PricingBox title={'Premium'} isFeatured price={'199 KM / mj.'}>
              <PricingLine icon={FiTruck} text={'30 vozila u registru'} />
              <PricingLine icon={FiUsers} text={'5 korisnika'} />
              <PricingLine icon={FiFileText} text={'Izvještaj o isteku termina'} />
              <PricingLine icon={FiCheckCircle} text={'Osiguranja, KASKO, Pregledi'} />
              <PricingLine icon={FiCheckCircle} text={'Računi, Gume, Kilometraže, Servisi'} />
              <PricingLine icon={FiShield} text={'Protivpožarni aparat'} />
              <PricingLine icon={FiMail} text={'E-Mail obavještenja o isteku'} />
            </PricingBox>
            <PricingBox title={'Enterprise'} price={'Prilagođeno Vašim potrebama'}>
              <PricingLine icon={FiTruck} text={'Prilagođen broj vozila u registru'} />
              <PricingLine icon={FiUsers} text={'Prilagođen broj korisnika u registru'} />

              <PricingLine icon={FiShield} text={'Sve funkcionalnosti iz paketa Premium'} />
            </PricingBox>
          </Stack>

          <Spacer h={'150px'} />
          <Stack direction={['column', 'row']} spacing={10} alignItems={'flex-start'} w={'full'}>
            <Box rounded={'lg'} border={'solid 1px'} borderColor={'gray.200'} p={10} backdropFilter='auto' backdropBlur='8px' bg={'purple.50'} w={'full'}>
              <Text fontSize={'2xl'} fontWeight={600} >Želite više informacija o našoj platformi?</Text>
              <Text mt={5}>
                Kontaktirajte nas putem e-maila <b>info@uvp.ba</b> i naš tim će Vam odgovoriti u najkraćem mogućem roku. <br /><br />Hvala Vam na interesovanju.
              </Text>
            </Box>

          </Stack>

          <Spacer h={'150px'} />


        </Container >
      </Box >
    </ChakraProvider >
  );
}

const PricingLine = ({ icon, text }) => {
  return (
    <HStack spacing={5}>
      <Icon as={icon} />
      <Text>{text}</Text>
    </HStack>
  )
}

const PricingBox = ({ title, price, children, isFeatured }) => {
  return (
    <Box border={'solid 1px'} borderColor={isFeatured ? 'purple.200' : 'white'} display={'flex'} flexDir={'column'} gap={5} p={10} w={'full'} rounded={'lg'} bg={'white'} boxShadow={'2xl'}>
      <Text fontSize={'lg'} fontWeight={400}>{title}</Text>
      <Text fontSize={'2xl'} fontWeight={600}>{price}</Text>
      <Divider />
      {children}
    </Box>
  )
}

export default App;
