import { extendTheme } from "@chakra-ui/react"
import '@fontsource/poppins';
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';


const theme = extendTheme({
  fonts: {
    heading: `Poppins, sans-serif`,
    body: `Poppins, sans-serif`
  },
  // button style
  components: {
    Button: {
      baseStyle: {
        fontWeight: "light",
        fontSize: 'sm',
        py: 7
      }
    },
  },
});

export default theme;
